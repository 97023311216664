import * as React from "react"
import styled from "styled-components"
import bgImage from "../../images/homepage/hero_banner_bg.png"
import { successColor } from "../../static/styles/colors"
import Image from "../common/heroImage"
import { FaArrowRight } from "@react-icons/all-files/Fa/FaArrowRight"
import { ButtonPrimary, Underline } from "../common/styledComponentsHelper"
import { Link } from "gatsby"

const EventHeroForward = ({ desktopImg, mobileImg, forwardLink }) => {
  return (
    <div className="container-fluid overflow-hidden">
      <BgWallpaper className="row flex-lg-row-reverse align-items-center">
        <div className="col-lg-7">
          <div className="container">
            <div className="mb-5">
              <Underline className="display-4 fw-bold d-none d-sm-block lh-1 text-uppercase text-light">
                Live Online Seminar Bierdeckelkalkulation
              </Underline>
              <Underline className="display-4 fw-bold lh-1 d-sm-none text-uppercase text-light">
                Live Online Seminar Bierdeckel kalkulation
              </Underline>
            </div>
            <div className="fs-1 text-success mb-5">
              Mit 3 Zahlen zu mehr Gewinn!
            </div>
          </div>
          <div className="container mb-lg-0 mb-5">
            <div className="row">
              <div className="col-md-8 col-12 mb-3">
                <p className="text-uppercase text-light">
                  <span className="fs-5 text-uppercase text-light pt-3">
                    <strong className="fw-bold">
                      F<span className="text-lowercase">achhandwerk</span>360
                    </strong>{" "}
                    bietet Ihnen kostenlos ein unvergessliches Live Online
                    Seminar zur Bestimmung Ihrer Preisuntergrenze
                  </span>
                </p>
                <Link to={forwardLink} className="text-decoration-none">
                  <ButtonPrimary className="align-items-center d-flex">
                    Jetzt kostenlos Anmelden
                    <FaArrowRight className="ms-3" color={successColor} />
                  </ButtonPrimary>
                </Link>
              </div>
              <div className="col-md-4 col-12">
                <a
                  href="https://www.provenexpert.com/fachhandwerk360-dreier-herber-gbr/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                  title="Kundenbewertungen &amp; Erfahrungen zu Fachhandwerk360 Dreier & Herber GbR. Mehr Infos anzeigen."
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                  }}
                  className="d-md-flex justify-content-end"
                >
                  <img
                    src="https://images.provenexpert.com/18/64/b20854e92890645f836d7a4bb109/widget_portrait_180_de_0.png"
                    alt="Erfahrungen &amp; Bewertungen zu Fachhandwerk360 Dreier & Herber GbR"
                    style={{ border: "0", width: "60%", minWidth: "125px" }}
                    className="d-none d-md-block"
                  />
                  <img
                    src="https://images.provenexpert.com/18/64/b20854e92890645f836d7a4bb109/widget_portrait_180_de_0.png"
                    alt="Erfahrungen &amp; Bewertungen zu Fachhandwerk360 Dreier & Herber GbR"
                    style={{ border: "0", width: "157px" }}
                    className="d-md-none d-block"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 p-0">
          <Image
            alt="Hero Banner"
            filenameDesktop={desktopImg}
            filenameMobile={mobileImg}
            style={{
              filter: `drop-shadow(10px 0px 25px ${successColor})`,
            }}
          />
        </div>
      </BgWallpaper>
    </div>
  )
}

const BgWallpaper = styled.div`
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
`

export default EventHeroForward
