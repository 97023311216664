import React from "react"
import Layout from "../../../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import EventHeroForward from "../../../components/online-seminar/eventHeroForward"
import { BsFileText } from "@react-icons/all-files/bs/BsFileText"
import { BiTask } from "@react-icons/all-files/bi/BiTask"
import { RiMoneyEuroBoxLine } from "@react-icons/all-files/ri/RiMoneyEuroBoxLine"
import { FaArrowRight } from "@react-icons/all-files/Fa/FaArrowRight"
import Video from "../../../components/common/video"
import ReviewContainer from "../../../components/review-container"
import {
  customerReview,
  customerVoices,
} from "../../../static/text/commonContent"
import {
  Videocontainer,
  BGLinearGradient,
  Separator,
  SeparatorColorFull,
  SeparatorExtended,
  SignUpButton,
} from "../../../components/common/styledComponentsHelper"
import {
  offerText,
  taskText,
  priceText,
  addedValueText,
  eventDescriptionText,
  rdDescription,
  mhDescription,
} from "../../../static/text/eventContent"
import { successColor, darkColor } from "../../../static/styles/colors"

export const Head = () => (
  <>
    <title>Live Online Seminar Bierdeckelkalkulation | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const Event = () => {
  return (
    <Layout>
      <EventHeroForward
        desktopImg="homepage_banner_desktop.png"
        mobileImg="homepage_banner_mobile.png"
        forwardLink="/online-seminar/sm/datum"
      />
      <BGLinearGradient>
        <Separator />
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <h2 className="text-uppercase fw-bold text-light text-start">
                Bierdeckelkalkulation für das{" "}
                <span className="text-success">Bauhandwerk</span>
              </h2>
              <br />
              <div className="text-start text-light fs-5">
                {eventDescriptionText}
                <br />
                <SignUpButton
                  className="btn btn-success px-5 py-2 text-dark text-uppercase mb-3"
                  to="/online-seminar/sm/datum"
                >
                  Jetzt kostenlos Anmelden{" "}
                  <FaArrowRight className="ms-1 mb-2" color={darkColor} />
                </SignUpButton>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <StaticImage
                src="../../../images/event/live_online_seminar.png"
                layout="fullWidth"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Martin Herber Vortrag"
                placeholder="none"
                className="rounded shadow"
              />
            </div>
          </div>
        </div>
        <Separator />
        <div className="bg-warning bg-opacity-25 mt-5 pt-5">
          <Separator />
          <h2 className="text-uppercase fw-bold text-light text-center">
            Inhalte und <span className="text-success">Ziele</span>
          </h2>
          <div className="container">
            <div className="row justify-content-between p-3">
              <div className="col-lg-12 text-light text-center p-4 fs-4">
                Ihr Handwerksbetrieb kann mehr! <br />
                Sie verstehen Ihr Handwerk – und wir unseres! Wir helfen Ihnen
                beim:
              </div>
              <div className="col-lg-4 text-light text-center p-4 mb-4">
                <div className="bg-dark rounded shadow p-4 h-100">
                  <BsFileText size="3rem" color={successColor} />
                  <h3>Angebote erstellen</h3>
                  <p>{offerText}</p>
                </div>
              </div>
              <div className="col-lg-4 text-light text-center p-4 mb-4">
                <div className="bg-dark rounded shadow p-4 h-100">
                  <BiTask size="3rem" color={successColor} />
                  <h3>Aufgaben vereinfachen</h3>
                  <p>{taskText}</p>
                </div>
              </div>
              <div className="col-lg-4 text-light text-center p-4 mb-4">
                <div className="bg-dark rounded shadow p-4 h-100">
                  <RiMoneyEuroBoxLine size="3rem" color={successColor} />
                  <h3>Preise kalkulieren</h3>
                  <p>{priceText}</p>
                </div>
              </div>
              <div className="col-lg-12 text-center p-4">
                <SignUpButton
                  className="btn btn-success px-5 py-2 text-dark text-uppercase mb-3"
                  to="/online-seminar/sm/datum"
                >
                  Jetzt kostenlos Anmelden{" "}
                  <FaArrowRight className="ms-1 mb-2" color={darkColor} />
                </SignUpButton>
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <Separator />
        <div className="container p-sm-5 p-3 w-75">
          <div className="row align-items-center">
            <div className="col-lg-6 p-3 pe-0 ms-auto d-block">
              <StaticImage
                src="../../../images/event/fhw360/Bierdeckel_drei_zahlen.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Bierdeckel"
                placeholder="none"
                layout="fullWidth"
                className="mx-auto"
                style={{ maxWidth: "500px" }}
              />
            </div>
            <div className="col-lg-6">
              <table className="h-100">
                <tbody>
                  <tr>
                    <td className="pt-3 pb-3 text-sm-start align-middle text-start">
                      <h2 className="text-uppercase fw-bold text-light">
                        Mehrwert:
                      </h2>
                      <div className="text-light mt-4">{addedValueText}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <SeparatorColorFull />
        <ReviewContainer />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-12">
              <div id="pewl" style={{ maxWidth: "100%" }} />
            </div>
          </div>
        </div>
        <SeparatorExtended />
        <div className="container-fluid p-0 position-relative">
          <StaticImage
            src="../../../images/event/die_profis.png"
            layout="fullWidth"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Profis"
            placeholder="none"
          />
          <CenterText className="text-center text-light text-uppercase fw-bold">
            Wenn's drauf ankommt, lieber mal die{" "}
            <span className="text-success">Spezialisten machen lassen!</span>
            <SignUpButton
              className="btn btn-success w-50 mx-auto mt-3 px-5 py-2 text-dark text-uppercase mb-3 d-lg-block d-none"
              to="/online-seminar/sm/datum"
            >
              Jetzt kostenlos Anmelden{" "}
              <FaArrowRight className="ms-1 mb-2" color={darkColor} />
            </SignUpButton>
          </CenterText>
        </div>
        <SignUpButton
          className="btn btn-success px-5 py-2 text-dark text-uppercase mb-3 mt-4 w-75 mx-auto d-lg-none d-block"
          to="/online-seminar/sm/datum"
        >
          Jetzt kostenlos Anmelden{" "}
          <FaArrowRight className="ms-1 mb-2" color={darkColor} />
        </SignUpButton>
        <Separator />
        <div className="container">
          <div className="container">
            <h2 className="text-light text-uppercase text-bold text-center fw-bold">
              Teilnehmerstimmen <span className="text-success">(Auszug)</span>
            </h2>
            <br />
            {customerVoices}
          </div>
        </div>
        <SeparatorColorFull />
        <div className="bg-dark">
          <Separator />
          <div className="container">
            <h2 className="text-light text-uppercase text-bold text-center fw-bold">
              Über die <span className="text-success">Fachhandwerk360</span>
            </h2>
            <Separator />
            <div className="row">
              <div className="col-md-6">
                <div className="row justify-content-center">
                  <p className="text-light fs-5 fw-bold text-center">
                    Rainer Dreier
                    <br /> Gründer und Gesellschafter
                  </p>
                  <StaticImage
                    src="../../../images/event/rd_profile.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Rainer Dreier"
                    placeholder="none"
                    className="w-50"
                  />
                  <div className="text-light text-center mt-3 d-flex justify-content-center fs-5">
                    <p className="w-50">{rdDescription}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row justify-content-center">
                  <p className="text-light fs-5 fw-bold text-center">
                    Martin Herber
                    <br /> Gründer und Gesellschafter
                  </p>
                  <StaticImage
                    src="../../../images/event/mh_profile.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Martin Herber"
                    placeholder="none"
                    className="w-50"
                  />
                  <div className="text-light text-center mt-3 d-flex justify-content-center fs-5">
                    <p className="w-50">{mhDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Separator />
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <SignUpButton
                  className="btn btn-success px-5 py-2 text-dark text-uppercase mb-3"
                  to="/online-seminar/sm/datum"
                >
                  Jetzt kostenlos Anmelden{" "}
                  <FaArrowRight className="ms-1 mb-2" color={darkColor} />
                </SignUpButton>
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <SeparatorExtended />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <Videocontainer>
                <Video
                  videoSrcURL="https://www.youtube-nocookie.com/embed/KzZwXMKM3zo?rel=0"
                  videoTitle="Die Bierdeckelkalkulation - Das sagen unsere Handwerkskunden!"
                  videoStyle={{
                    top: "0",
                    left: "0",
                    height: "100%",
                  }}
                />
              </Videocontainer>
            </div>
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <table className="h-100 w-100">
                <tbody>
                  <tr>
                    <td className="pt-3 pb-3 align-middle text-start text-light fs-5">
                      <h2 className="text-uppercase fw-bold">
                        Das sagen unsere{" "}
                        <span className="text-success">Kunden</span>
                      </h2>
                      <div className="text-light mt-4 fs-5">
                        {customerReview}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <SeparatorExtended />
        <div className="bg-primary">
          <Separator />
          <h2 className="text-uppercase fw-bold text-light text-center">
            Referenzen <span className="text-success">(Auszug)</span>
          </h2>
          <div className="container mt-5">
            <div className="row">
              <div className="col-lg-4">
                <StaticImage
                  src="../../../images/event/logo-HERO.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Meisterteam-Logo"
                  placeholder="none"
                  className="mx-auto m-3"
                  style={{ maxWidth: "500px" }}
                />
                <StaticImage
                  src="../../../images/event/logo-topaTeam.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="TopaTeam-Logo"
                  placeholder="none"
                  className="mx-auto m-3"
                  style={{ maxWidth: "500px" }}
                />
              </div>
              <div className="col-lg-4">
                <StaticImage
                  src="../../../images/event/fhw360/logo-schmidt-rudersdorf.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Schmidt-Rudersdorf Logo"
                  placeholder="none"
                  className="mx-auto m-3"
                  style={{ maxWidth: "500px" }}
                />
                <StaticImage
                  src="../../../images/event/logo-kreishandwerkerschaften_bundesweit.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Kreishandwerkerschaften_bundesweit-Logo"
                  placeholder="none"
                  className="mx-auto m-3"
                  style={{ maxWidth: "500px" }}
                />
              </div>
              <div className="col-lg-4">
                <StaticImage
                  src="../../../images/event/logo-emasters.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="emasters-Logo"
                  placeholder="none"
                  className="mx-auto m-3"
                  style={{ maxWidth: "500px" }}
                />
                <StaticImage
                  src="../../../images/event/logo-handwerkskammer_frankfurt.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Handwerkskammer_frankfurt-Logo"
                  placeholder="none"
                  className="mx-auto m-3"
                  style={{ maxWidth: "500px" }}
                />
              </div>
            </div>
          </div>
          <Separator />
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <SignUpButton
                  className="btn btn-success px-5 py-2 text-dark text-uppercase mb-3"
                  to="/online-seminar/sm/datum"
                >
                  Jetzt kostenlos Anmelden{" "}
                  <FaArrowRight className="ms-1 mb-2" color={darkColor} />
                </SignUpButton>
              </div>
            </div>
          </div>
          <Separator />
        </div>
      </BGLinearGradient>
    </Layout>
  )
}

const CenterText = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`

export default Event
